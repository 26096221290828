import axios from 'axios';
import {baseUrl} from '@/js/util.js'

// 获取精品案例页面的项目类型下拉
export const get_select_projectTypeList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_projectTypeList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}

// 获取精品案例分页数据
export const f_get_caseSharingPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_caseSharingPageList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取精品案例下的模型数据
export const f_get_csConstructionDrawings=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_csConstructionDrawings`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取精品案例下的过程文件
export const f_get_csProcessFileTree=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_csProcessFileTree`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取精品案例下的过程文件转换pptx
export const Show_File=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/UploadFile/Show_File`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}