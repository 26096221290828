<template>
    <div id="courseIntroductionDetails">
        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">精品案例</span>>
                <span style="color: #999999;cursor: pointer;" @click="detailsClick">精品案例详情</span>>
                <span>BIM模型</span>
            </div>


            <div style="width:100%;height: 1000px;display: flex;justify-content: space-between;">
                <div style="width:100%; float: left; display: flex;flex-direction: column;align-items: center;">
                    <div style="width:100%;height:40px;background:#fff;color:#76C8F6;text-align:center;line-height:40px;font-size: 18px;font-weight:600">
                        {{ caseName?caseName:'--' }}
                    </div>
                    <div style="width: 100%;">
                        <div id="renderContainer"></div>
                    </div>
                </div>
                <div class="rightBox">
                    <div style="width: 100%;height: 50px;line-height: 50px;text-align: center;border-bottom: 1px solid #F7F8FA;font-weight: bold;color: #333333;">
                        目录
                    </div>
                    <el-collapse v-model="activeNames"  style="margin: 10px;">
                    <el-collapse-item v-for="(item,index) in modelTreeData" :key="index" :name="item.isOrder">
                                    <template #title>
                                        <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;">{{ item.cdName }}</span>
                                    </template>
                                                <div style="margin-top: 10px;padding-left: 20px;" :class="modelIndex == item2.file?'highlight':''" v-for="(item2,index2) in item.child" :key="index2">
                                                    <p><img style="vertical-align: middle;cursor: pointer;margin-right:10px" :src="require('@/assets/img/courseTechingimg/BIM.png')" alt=""><span style="cursor: pointer;" @click="modelClick(item2,index2)">{{ item2.cdName }}</span></p>
                                                </div>
                                               
                    </el-collapse-item>
            </el-collapse>
                </div>
            </div>
        
        </div>
        
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted,onBeforeUnmount, computed,watch } from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl,reception} from "@/js/util"
import { pkpmView, InnerExtensions, ViewEventType, Utilities,Point3,Drawing3D,InstanceEventType,Vector3 } from 'pkpm.ViewEngine'
import bimModel from '../../../components/bimCodeModel.vue'
import {f_get_csConstructionDrawings} from '../../../js/public'
export default {
    setup () {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const state = reactive({
            activeNames:1,
            modelIndex:0,
            viewOptions :{
            wokringDirectory: reception+'/#/home/bimModel', // 前端路径，
            containerId: 'renderContainer', // 加载引擎的DOM元素
            token: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjREODJDRUVBRDhEMDAyODk2NjJDNjExMzAwRkJGN0M4NzU0Q0I1OEFSUzI1NiIsInR5cCI6ImF0K2p3dCIsIng1dCI6IlRZTE82dGpRQW9sbUxHRVRBUHYzeUhWTXRZbyJ9.eyJuYmYiOjE2NzgwODM4MDIsImV4cCI6MTY3ODA4NTAwMiwiaXNzIjoiaHR0cHM6Ly91Yy5hcGkucGtwbS5jbiIsImF1ZCI6WyJhcGkxIiwiaHR0cHM6Ly91Yy5hcGkucGtwbS5jbi9yZXNvdXJjZXMiXSwiY2xpZW50X2lkIjoiVW5pZmllZF9BY2Nlc3NfU2VydmljZSIsInN1YiI6IjE0MzA2OSIsImF1dGhfdGltZSI6MTY3ODA4MzgwMiwiaWRwIjoibG9jYWwiLCJuYW1laWQiOiIxNDMwNjkiLCJ1bmlxdWVfbmFtZSI6IjE0aHlnTVd3WkxFIiwiYXBwSWQiOiJVbmlmaWVkX0FjY2Vzc19TZXJ2aWNlIiwicmVmcmVzaFRva2VuIjoiNmY0ZDcwNjQtNmJiNC00N2MzLTkxODgtYjM5ODE0YTY2MzEzIiwibGFzdExvZ2luVGltZSI6IjAzLzA2LzIwMjMgMTQ6MjM6MjIgKzA4OjAwIiwibG9naW5UeXBlIjoiUGVyc29uYWwiLCJqdGkiOiJDNjIyRTY2NkMwOUVBQUIzMEVDRTc2MUVGOTUyQjc3MCIsImlhdCI6MTY3ODA4MzgwMiwic2NvcGUiOlsiYXBpMSIsIm9wZW5pZCIsInByb2ZpbGUiLCJvZmZsaW5lX2FjY2VzcyJdLCJhbXIiOlsicHdkIl19.AHwnItuwtkXebaqp5cMXeW51EcerW2o2aGLmKBvLova6YJVmKzJIZ4MN45pfwx3S-HSjnWJbCd79Go4ZagVum8YqX9ntc7BTJ2XoEvzpeipdMf783Za_TsxLjy1_llSQgpS0eSbe6Gv2trU9xLhdvR60wOqS8slC28NHDUkXg9xL3A_oCsP6mv39TM2wfhrzar_mjFcc2JrnK2N9p-msjEe39MkkUJyaTGwobqMaIqMfzLwYLnjwHbPLG24r85NV9U6LppTP_5i8CPZQa3nm6NpeksF0yH_W7nqJzJ_WTSuW3LmBivonK9F1ysAHh3eK0jwHIi5D9pTHSDuTP2K0WA', // 访问平台、获取模型token
            dmsEndpoint: 'https://cloud.dt.pkpm.cn',
            env: "prod",
            extensions: [
            InnerExtensions.DefaultTool,  // 默认工具，包括旋转、移动和放大缩小
            InnerExtensions.Home,
            InnerExtensions.FitWindow,
            InnerExtensions.FullScreen,
            InnerExtensions.BackgroundColor,
            InnerExtensions.BasicInfo, //基础信息
            InnerExtensions.Settings, //设置
            InnerExtensions.Isolation, //隔离
            InnerExtensions.Hiding, //隐藏
            InnerExtensions.Attributes, //属性
            InnerExtensions.DWGLayer, //DWG图层
            InnerExtensions.InstanceTree,// 构件树
            InnerExtensions.Pushpin,
            InnerExtensions.TitleBlock, //二维ocr支持
            ], // 需要加载的插件
            
            excludeExtensions: [
           
            ] // 引擎默认加载但是不需要的些插件
            },
            viewApp:pkpmView,
            documentChildren:false,
            modelTreeData:[],
            id:route.query.id,
            caseName:route.query.caseName,
            pushpinMgr:'',
            pastId:'',
            p1:'',
            p2:'',
            p3:'',
            p4:'',
            pushpins:[],
            modelShow:false,
            Number:0,
        })
        onMounted(() => {
            if (location.href.indexOf("#reloaded") == -1) {
                location.href = location.href + "#reloaded";
                location.reload();
                }
          
            // window.addEventListener('beforeunload', function () {
            //     state.viewApp.stop();
            // })
            methods.f_get_csConstructionDrawings();
           
        });

        onBeforeUnmount(() =>{
            state.viewApp.stop() // 停止
            console.log(state.viewApp);
        })
    const methods = {
        // 获取模型的数据
        f_get_csConstructionDrawings(){
            let params = {
                caseSharingId:state.id,
                cdType:2
            }
            f_get_csConstructionDrawings(params).then(res=>{
                if (res.data.code == 1) {
                    state.modelTreeData = res.data.data;
                    setTimeout(() => {
                        methods.getViewModel()
                    }, 1000);
                    
                }
            })
        },
        // 点击切换模型数据
        modelClick(row,index2){
  
            state.modelIndex = row.file;
           if (state.pastId == row.file) {
            return
           }
            console.log(state.pastId);
            if (state.pastId && state.pastId != row.file) {
                 state.viewApp.unloadModel(state.pastId, 1)
                 console.log(111);
            }
            // state.viewApp.stop();
            // state.viewApp = new pkpmView(state.viewOptions) // 创建实例
            // state.viewApp.start();
            state.pastId = row.file;
            if (state.pushpins.length > 0) {
                            state.pushpins.forEach((pushpins, pp) => {
                                console.log(pushpins);
                            state.pushpinMgr.removePushpin(pushpins.id);
                        })
                        }
            setTimeout(() => {
                 state.viewApp.loadModels([{documentId: row.file, type: '.xdbz', version: 1, noAutoFit:true}], true);
            }, 1000);
           
        },
        getViewModel: () =>{
            console.log(state.viewOptions.wokringDirectory);
            state.viewApp = new pkpmView(state.viewOptions) // 创建实例
           
            state.viewApp.iView.subscribe({ next: (e) => {
                switch(e.type) {
                case 
                ViewEventType.EXTENSION_LOADED:
                    if (e.data.type === InnerExtensions.Pushpin) {
                        state.pushpinMgr = e.data.app;
                        state.pushpinMgr.subscribe({ next: (e) => {
                            switch (e.type) {
                            case ViewEventType.PUSHPIN_CREATED:
                            const pin = e.data.pin;
                                state.pushpins.push(pin);    
                            break;
                            case ViewEventType.PUSHPIN_REMOVED:

                            break;
                            case ViewEventType.ON_PUSHPIN_CLICKED:
                                
                            break;
                            }
                            }})
                        }
                        break
                         case ViewEventType.SELECTION_ADDED:
                            
                        if (!e.data.objectID) return;
                        console.log(e);
                        const aabb = e.data.aabb;
                        const min = aabb.min;
                        const max = aabb.max;
                        const minx = Math.min(min[0], max[0])
                        const miny = Math.min(min[1], max[1])
                        const minz = Math.min(min[2], max[2])
                        const maxx = Math.max(min[0], max[0])
                        const maxy = Math.max(min[1], max[1])
                        const maxz = Math.max(min[2], max[2])

                        state.p1 = Vector3.create(minx, maxy, (minz + maxz) / 2);
                        const p2 = Vector3.create(minx, maxy + 10, (minz + maxz) / 2);
                        const p3 = Vector3.create(maxx, maxy + 15, (minz + maxz) / 2);
                        state.p4 = Vector3.create(maxx, maxy, (minz + maxz) / 2);
                        const p5 = Vector3.create((minx + maxx) / 2, maxy + 5, (minz + maxz) / 2);
                        const imgurl = '';
                        state.viewApp.iView.scene.removePostSceneGroup('test');
                        state.pushpinMgr.addPushpinByText(1, p2, '构件ID'+e.data.dbID, { fontColor: 'black', bkColor: 'white', fontSize: 18, width: 2.4, height: 1.7}, false);
                        // state.pushpinMgr.addPushpinImage(1, p5, imgurl, { fontColor: 'blue', bkColor: 'white', fontSize: 24, width: 7.4, height: 2.7}, false)

                        break;
                        case ViewEventType.SELECTION_REMOVED:
                        console.log(state.pushpins);
                        if (state.pushpins.length > 0) {
                            state.pushpins.forEach((pushpins, pp) => {
                                console.log(pushpins);
                            state.pushpinMgr.removePushpin(pushpins.id);
                        })
                        }
                        break;
                        }   
                   

            }})
                
            state.viewApp.start() // 启动
            state.pastId = state.modelTreeData[0].child[0].file;
            state.modelIndex = state.modelTreeData[0].child[0].file;
            console.log( state.pastId);
            state.viewApp.loadModels([{documentId: state.pastId, type: '.xdbz', version: 1, name: "A_FZJD_平面02_模型.0001(1).xdbz",noAutoFit:true}], true);

                

          
           },
          
           
           
        indexClick(){
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push('/home/index');
            },
            courseClick(){
                router.push('/home/boutiqueCases');
            },
            detailsClick(){
                router.push('/home/casesDetail');
            },
            showChildren(){
                state.documentChildren = !state.documentChildren
            },
    }
        return{
            ...toRefs(state),
            ...methods,
        }
    },
    components:{bimModel}
}
</script>

<style  lang="scss" scoped>
@import '../../../../node_modules/pkpm.ViewEngine/lib/pkpm.ViewEngine.min.css';
    #renderContainer{
        width: 100%;
        height: 960px;
    }
#courseIntroductionDetails{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
    }
    .breadCrumbs{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .rightBox{
        width:354px;
        height:auto;
        background: #fff;
        float: right;
        
    }
    .highlight{
        background: #ccc;
    }
    
   :deep .el-collapse-item__header{
        background: #F7F8FA;
    }
    :deep .el-collapse-item__wrap{
        border: none;
    }
}
</style>